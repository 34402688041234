export enum StandaloneFilterTypes {
    BUY_AGAIN = 'BUY_AGAIN',
    ON_SALE = 'ON_SALE',
    DIGITAL_COUPON = 'DIGITAL_COUPON',
    FUEL_SAVER = 'FUEL_SAVER'
}

export const CATEGORY = 'CATEGORY';

export enum SearchCategories {
    PRODUCTS = 'Products',
    RECIPES = 'Recipes',
    IDEAS = 'Ideas',
    VIDEOS = 'Videos'
}
